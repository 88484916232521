import {config} from "../function/config"
import {adminAPI} from "../function/api"
import call_alert from "../function/alert"



function set_admin_session(data){
  let string_admin_setting = JSON.stringify(data)
  sessionStorage.setItem("admin",string_admin_setting)
}
// yihsinma719@gmail.com
if(sessionStorage.getItem("admin")){
  let admin_setting = JSON.parse(sessionStorage.getItem("admin"))
  if(admin_setting.type=="user"){
    // school
    $.get(adminAPI("GetRegisterSchool"),function(result){
      let res = JSON.parse(result)
      if(res.status){
        // console.log(res.school)
        res.school.forEach(e=>{
          $("#school_id").append(`<option value="${e.school_id}">${e.school_name}</option>`)
        })
        $("#phone").val(admin_setting.phone)
        $("#school_id").val(admin_setting.school_id)
        $("#type").val(admin_setting.type)
      }
    })
  }else{
    $("#type").val(admin_setting.type)
    $("#phone,#school_id").attr("disabled",true).val("")
  }
}else{
  let admin_setting = {
    phone: "",
    school_id: "",
    type: "user",
    page: "1",
    page_size: "10",
  }
  set_admin_session(admin_setting)
  $("#type").val(admin_setting.type)
}

$('#type').on("change",function(){
  if($(this).val()=="admin"){
    $("#school_id,#phone").attr("disabled",true).val("")
    member_data.phone=""
    member_data.school_id=""
  }else{
    $("#school_id,#phone").attr("disabled",false)
  }
})

let head = {
  user: `
    <thead>
      <tr>
        <th>姓名</th>
        <th class="r-h">學校</th>
        <th class="r-h">系別</th>
        <th class="r-h">性別</th>
        <th class="r-h">電話</th>
        <th>EMAIL</th>
        <th class="r-h">註冊時間</th>
        <th></th>
      </tr>
    </thead>
    <tbody class=""></tbody>
  `,
  admin: `
    <thead>
      <tr>
        <th>姓名</th>
        <th>EMAIL</th>
        <th class="r-h">註冊時間</th>
        <th></th>
      </tr>
    </thead>
    <tbody class=""></tbody>
  `
}

let body = {
  user: `
    <tr>
      <td><a href="${config.url}editmember/{{id}}">{{name}}</a></td>
      <td class="r-h">{{school_name}}</td>
      <td class="r-h">{{department_name}}</td>
      <td class="r-h">{{gender}}</td>
      <td class="r-h">{{phone_no}}</td>
      <td>{{email}}</td>
      <td class="r-h">{{credate}}</td>
      <td>
        <div class="btn-group">
          <button type="button" class="btn btn-light dropdown-toggle" aria-expanded="false" data-bs-toggle="dropdown"></button>
          <ul class="dropdown-menu dropdown-menu-end">
            <li><a href="${config.url}editmember/{{id}}" class="dropdown-item">編輯</a></li>
            <li><a href="#" data-delete="{{id}}" class="dropdown-item">刪除</a></li>
          </ul>
        </div>
      </td>
    </tr>
  `,
  admin: `
    <tr>
      <td><a href="${config.url}editadmin/{{id}}">{{name}}</a></td>
      <td>{{email}}</td>
      <td class="r-h">{{credate}}</td>
      <td>
        <div class="btn-group">
          <button type="button" class="btn btn-light dropdown-toggle" aria-expanded="false" data-bs-toggle="dropdown"></button>
          <ul class="dropdown-menu dropdown-menu-end">
            <li><a href="${config.url}editadmin/{{id}}" class="dropdown-item">編輯</a></li>
            <li><a href="#" data-delete="{{id}}" class="dropdown-item">刪除</a></li>
          </ul>
        </div>
      </td>
    </tr>
  `
}

let member_data = JSON.parse(sessionStorage.getItem("admin"))
let all_rows = []
// console.log(member_data)

function load_table(call=false){
  // console.log(true)
  call_alert("warning","載入中")
  all_rows = []
  if(member_data.type=="admin"){
    $("#filter>button").hide()
  }else{
    $("#filter>button").show()
  }
  $.post(adminAPI("GetUserList"),member_data,function(result){
    set_admin_session(member_data)
    let res = JSON.parse(result)
    // console.log(res)
    $("#member-table").html(head[member_data.type])
    if(res.status){
      for(let i=0;i<res.all_rows;i++){
        all_rows.push(true)
      }
      res.user_list.forEach(e=>{
        let push
        if(member_data.type=="user"){
          push = body.user.replace(/{{name}}/g,e.name)
                          .replace(/{{school_name}}/g,(e.school_id!=-1)?e.school_name:e.other_school)
                          .replace(/{{department_name}}/g,(e.department_id!=-1)?e.department_name:e.other_school)
                          .replace(/{{gender}}/g,(e.gender=="m")?"男":"女")
                          .replace(/{{phone_no}}/g,e.phone_no)
                          .replace(/{{email}}/g,e.email)
                          .replace(/{{credate}}/g,e.credate)
                          .replace(/{{id}}/g,e.id)
        }else{
          push = body.admin.replace(/{{name}}/g,e.name)
                           .replace(/{{email}}/g,e.email)
                           .replace(/{{credate}}/g,e.credate)
                           .replace(/{{id}}/g,e.id)
        }
        $("#member-table tbody").append(push)
      })

      // delete
      $('[data-delete]').on("click",function(){
        let id = $(this).data('delete')
        let confirm = `
          <div class="modal fade" data-bs-backdrop="static" id="delete-confirm" tabindex="-1" data-bs-keyboard="false">
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content certificate-loading">
                <p class="text-center mb-4">確定刪除使用者？</p>
                <div class="row g-3">
                  <div class="col-6 text-end">
                    <button id="delete-confirm-cancel" class="btn btn-outline-dark btn-main" type="button">取消</button>
                  </div>
                  <div class="col-6">
                    <button id="delete-confirm-submit" class="btn btn-dark btn-main" type="button">確定</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          `
        // modal
        $('body').append(confirm)
        let delete_confirm = new bootstrap.Modal('#delete-confirm')
        delete_confirm.show()
        $("#delete-confirm-cancel").on("click",function(){
          delete_confirm.hide()
          setTimeout(()=>{
            $("#delete-confirm").remove()
          },500)
        })
        $("#delete-confirm-submit").on("click",function(){
          delete_confirm.hide()
          $.post(adminAPI("DeleteUser"),{user_id:id},function(result){
            let res = JSON.parse(result)
            if(res.status){
              call_alert("warning",res.msg)
              setTimeout(()=>{
                load_pagination()
              },500)
            }else{
              call_alert("danger",res.msg)
            }
            setTimeout(()=>{
              $("#delete-confirm").remove()
            },500)
          })
        })
      })
      if(call){
        call_alert("success","已載入")
      }
    }else{
      call_alert("danger",res.msg)
      setTimeout(()=>{
        location.href=config.home
      },config.redirect_timeout)
    }
  })
}

function load_pagination(){
  load_table()
  let timer
  timer = setInterval(() => {
    if(all_rows.length>0){
      window.clearInterval(timer)
      let pagination = $('#pagination-container').pagination({
        dataSource: all_rows,
        pageSize: member_data.page_size,
        pageNumber: member_data.page,
        showSizeChanger: true,
        showNavigator: true,
        formatNavigator: "共 <%= totalNumber %> 筆",
        callback: function(data, pagination){
          member_data.page_size = pagination.pageSize
          member_data.page = pagination.pageNumber
          set_admin_session(member_data)
          load_table(true)
        }
      })
    }
  }, 100);
}

load_pagination()

$("#filter").on("submit",function(e){
  e.preventDefault()
  let data = $("#filter").serializeArray()
  // console.log(data)
  data.forEach(e=>{
    member_data[e.name]=e.value
  })
  // load_table()
  load_pagination()
})


// pagination.pagination(member_data.page)
// console.log(member_data.page)
