import {config} from "../function/config"
import is from "../function/is"

let pls_login = `<div class="modal fade" data-bs-backdrop="static" id="pls-login" tabindex="-1">
<div class="modal-dialog modal-dialog-centered">
  <div class="modal-content pls-login">
    <button type="button" aria-label="Close" class="btn-close" data-bs-dismiss="modal"></button>
    <div class="title text-center">
      <h3>您尚未登入</h3>
    </div>
    <p class="text-center">登入會員，享受完整功能！</p><a href="${config.login_page}" class="btn btn-dark btn-main">立即登入</a>
  </div>
</div>
</div>`

let pls_login_modal
if(!config.user.logined){
  if(!is("#pls-login")){
    $("body").append(pls_login)
    pls_login_modal = new bootstrap.Modal('#pls-login')
  }
}

$("#homework").on("click",function(e){
  if(!config.user.logined){
    e.preventDefault()
    pls_login_modal.show()
  }
})
