import {config} from "../function/config"
import call_alert from "../function/alert"
import {userAPI,adminAPI } from "../function/api"
import {GetCity,GetSchool,GetDepartment} from "../function/school"

// let info = {}
// if(location.pathname.split("/")[2]!=""){
//   location.search.substring(1).split("&").forEach(e=>{
//     let data = e.split("=")
//     info[data[0]] = data[1]
//   })
// }else{
//   call_alert("danger","ID錯誤")
//   setTimeout(()=>{
//     location.href = config.admin_list_page
//   },config.redirect_timeout)
// }
let user_id = location.pathname.split("/")[2]

if(user_id!=""){
  $(`[name="user_id"]`).val(user_id)
  $.post(adminAPI("GetCurrentUserInfo"),{user_id:user_id},function(result){
    let res = JSON.parse(result)
    // console.log(res)
    $(`[name="type"]`).val((res.user.admin=="1")?"admin":"user")
    if(res.status){
      if(res.user.admin==0){
        $("[name='name']").val(res.user.name)
        $('[name="gender"][value="'+res.user.gender+'"]').attr("checked",true)
        // btd
        let btd = res.user.birthday.split("-")
        $("[name='year']").val(btd[0])
        $("[name='month']").val(btd[1])
        $("[name='date']").val(btd[2])
        $("[name='phone_no']").val(res.user.phone_no)
        $("[name='email']").val(res.user.email)
        $("[name='other_department']").val(res.user.other_department)
        $("[name='other_school']").val(res.user.other_school)
        $("[name='education']").val(res.user.education)
        $("[name='status']").val(res.user.status)
        
        GetCity(res.user.city)
        GetSchool(res.user.school_id)
        GetDepartment(res.user.department_id)
        if(res.learn.digital=="1"){
          $("[name='digital']").attr("checked",true)
        }
        if(res.learn.financial=="1"){
          $("[name='financial']").attr("checked",true)
        }
        if(res.learn.insurance=="1"){
          $("[name='insurance']").attr("checked",true)
        }
        if(res.learn.math=="1"){
          $("[name='math']").attr("checked",true)
        }
      }else{
        $("[name='name']").val(res.user.name)
        $("[name='email']").val(res.user.email)
        $("[name='status']").val(res.user.status)
      }
    }else{
      call_alert("danger",res.msg)
      setTimeout(()=>{
        location.href = config.admin_list_page
      },config.redirect_timeout)
    }
  })
}else{
  call_alert("danger","ID錯誤")
  setTimeout(()=>{
    location.href = config.admin_list_page
  },config.redirect_timeout)
}

$("form#admin-edit").validate({
  errorClass: "is-invalid",
  // validClass: "is-valid",
  onfocusout: function(element) {
    $(element).valid()
  },
  // debug: true,
  rules: {
    email: {
      required: true,
    },
    name: {
      required: true
    },
    year: {
      required: true,
      maxlength: 4
    },
    month: {
      required: true,
      maxlength: 2
    },
    date: {
      required: true,
      maxlength: 2
    },
    city: {
      required: true
    },
    school: {
      required: true
    },
    dept: {
      required: true
    },
    other_school: {
      required: true
    },
    other_department: {
      required: true
    },
    education: {
      required: true
    },
    phone_no: {
      required: true
    },
  },
  messages: {
    city: "請選擇學校所在縣市",
    school: "請選擇學校",
    dept: "請選擇系別",
    education: "請選擇學歷",
  },
  submitHandler: function(form) {
    let data = $(form).serializeArray()
    let type = $(`[name="type"]`).val()
    console.log(data)
    if(type=="user"){
      $.post(adminAPI("UpdateUser"),data,function(result){
        let data = JSON.parse(result)
        if(data.status){
          call_alert("success",data.msg)
        }else{
          call_alert("danger",data.msg)
        }
      })
    }
    if(type=="admin"){
      $.post(adminAPI("UpdateAdmin"),data,function(result){
        let data = JSON.parse(result)
        if(data.status){
          call_alert("success",data.msg)
        }else{
          call_alert("danger",data.msg)
        }
      })
    }
  }
});