import {config} from '../function/config';
import {userAPI} from "../function/api"
import call_alert from '../function/alert';

$(".brand a").attr("href",config.home)

$('#mobile-toggle').on('click',function(){
  $(this).toggleClass('active')
  $('body').toggleClass('menu-in')
})

let location_href = window.location.href
$('header#header .menu a,#mobile-header .menu a').each(function(){
  let href = $(this).attr('href')
  // console.log(location_href,href)
  if(location_href.indexOf(href)!=-1){
    $(this).closest('li').addClass('active')
  }
})

// member check
$.get(userAPI("GetUserInfo"),function(result){
  let res = JSON.parse(result)
  let login = `<a class="login" href="${config.login_page}">註冊 / 登入</a>`
  if(res.status){
    let color = `background-color: rgba(${Math.floor(Math.random() * 200)},${Math.floor(Math.random() * 200)},${Math.floor(Math.random() * 200)})`
    config.user.logined=true
    let logined = {
      user: {
        desk: `<div class="logined">
          <div class="avatar" style="${color}">${res.user.name.substring(0,1)}</div>
          <div class="member-menu">
            <ul>
              <li>Hi,${res.user.name}</li>
              <li class="hr"></li>
              <li><a href="${config.profile_page}">個人檔案</a></li>
              
              <li class="hr"></li>
              <li><a href="javascript:;" class="logout">登出</a></li>
            </ul>
          </div>
        </div>`,
        //<li><a href="${config.learning_page}">學習成就</a></li>
        mobile: `<div class="logined">
          <div class="avatar" style="${color}">${res.user.name.substring(0,1)}</div>
          <p>Hi,${res.user.name}</p>
          <div class="member-menu">
            <ul>
              <li><a href="${config.profile_page}">個人檔案</a></li>

            </ul>
          </div>
        </div>`
        // <li><a href="${config.learning_page}">學習成就</a></li>
      },
      admin: {
        desk: `<div class="logined">
          <div class="avatar" style="${color}">${res.user.name.substring(0,1)}</div>
          <div class="member-menu">
            <ul>
              <li>Hi,${res.user.name}</li>
              <li class="hr"></li>
              <li><a href="${config.admin_list_page}">會員列表</a></li>
              <li><a href="${config.profile_page}">個人檔案</a></li>
              <li class="hr"></li>
              <li><a href="javascript:;" class="logout">登出</a></li>
            </ul>
          </div>
        </div>`,
        mobile: `<div class="logined">
          <div class="avatar" style="${color}">${res.user.name.substring(0,1)}</div>
          <p>Hi,${res.user.name}</p>
          <div class="member-menu">
            <ul>
              <li><a href="${config.admin_list_page}">會員列表</a></li>
              <li><a href="${config.profile_page}">個人檔案</a></li>
            </ul>
          </div>
        </div>`
      },
      logout: `<a href="javascript:;" class="logout">登出</a>`
    }
    if(res.user.admin=="1"){
      $("#header .member-wrap").append(logined.admin.desk)
      $("#mobile-header .member-wrap").append(logined.admin.mobile)
      $("#mobile-header .bottom").append(logined.logout)
      // config
      config.user.type="admin"
    }else{
      $("#header .member-wrap").append(logined.user.desk)
      $("#mobile-header .member-wrap").append(logined.user.mobile)
      $("#mobile-header .bottom").append(logined.logout)
      // config
      config.user.type="user"
    }
  }else{
    config.user.logined=false
    $("#header .member-wrap").append(login)
    $("#mobile-header .member-wrap").append(login)
  }
  $("a.logout").on("click",function(e){
    e.preventDefault()
    $.get(userAPI("Logout"),function(result){
      let res = JSON.parse(result)
      if(res.status){
        call_alert("success","已登出")
        setTimeout(()=>{
          location.href=config.home
        },config.redirect_timeout)
      }
    })
  })
})