import call_alert from "../function/alert";
import {userAPI} from "../function/api"
import {config} from "../function/config"
// import is from "../function/is"

// videojs.addLanguage('zh-Tw',{
//   "Play": "播放",
//   "Pause": "暫停",
//   "Current Time": "目前時間",
//   "Duration": "總共時間",
//   "Remaining Time": "剩餘時間",
//   "Stream Type": "串流類型",
//   "LIVE": "直播",
//   "Loaded": "載入完畢",
//   "Progress": "進度",
//   "Fullscreen": "全螢幕",
//   "Exit Fullscreen": "退出全螢幕",
//   "Picture-in-Picture": "子母畫面",
//   "Exit Picture-in-Picture": "退出子母畫面",
//   "Mute": "靜音",
//   "Unmute": "開啟音效",
//   "Playback Rate": " 播放速率",
//   "Subtitles": "字幕",
//   "subtitles off": "關閉字幕",
//   "Captions": "內嵌字幕",
//   "captions off": "關閉內嵌字幕",
//   "Chapters": "章節",
//   "Close Modal Dialog": "關閉對話框",
//   "Descriptions": "描述",
//   "descriptions off": "關閉描述",
//   "Audio Track": "音軌",
//   "You aborted the media playback": "影片播放已終止",
//   "A network error caused the media download to fail part-way.": "網路錯誤導致影片下載失敗。",
//   "The media could not be loaded, either because the server or network failed or because the format is not supported.": "因格式不支援、伺服器或網路的問題無法載入媒體。",
//   "The media playback was aborted due to a corruption problem or because the media used features your browser did not support.": "由於影片檔案損毀或是該影片使用了您的瀏覽器不支援的功能，已終止播放媒體。",
//   "No compatible source was found for this media.": "無法找到相容此媒體的來源。",
//   "The media is encrypted and we do not have the keys to decrypt it.": "媒體已加密，無法解密。",
//   "Play Video": "播放影片",
//   "Close": "關閉",
//   "Modal Window": "強制回應視窗",
//   "This is a modal window": "此為強制回應視窗",
//   "This modal can be closed by pressing the Escape key or activating the close button.": "可以按ESC按鍵或關閉按鈕來關閉此視窗。",
//   ", opens captions settings dialog": ", 開啟標題設定對話框",
//   ", opens subtitles settings dialog": ", 開啟字幕設定對話框",
//   ", opens descriptions settings dialog": ", 開啟描述設定對話框",
//   ", selected": ", 選擇",
//   "captions settings": "字幕設定",
//   "Audio Player": "音訊播放器",
//   "Video Player": "視訊播放器",
//   "Replay": "重播",
//   "Progress Bar": "進度列",
//   "Volume Level": "音量",
//   "subtitles settings": "字幕設定",
//   "descriptions settings": "描述設定",
//   "Text": "文字",
//   "White": "白",
//   "Black": "黑",
//   "Red": "紅",
//   "Green": "綠",
//   "Blue": "藍",
//   "Yellow": "黃",
//   "Magenta": "紫紅",
//   "Cyan": "青",
//   "Background": "背景",
//   "Window": "視窗",
//   "Transparent": "透明",
//   "Semi-Transparent": "半透明",
//   "Opaque": "不透明",
//   "Font Size": "字型尺寸",
//   "Text Edge Style": "字型邊緣樣式",
//   "None": "無",
//   "Raised": "浮雕",
//   "Depressed": "壓低",
//   "Uniform": "均勻",
//   "Dropshadow": "下陰影",
//   "Font Family": "字型系列",
//   "Proportional Sans-Serif": "調和間距無襯線字型",
//   "Monospace Sans-Serif": "等寬無襯線字型",
//   "Proportional Serif": "調和間距襯線字型",
//   "Monospace Serif": "等寬襯線字型",
//   "Casual": "輕便的",
//   "Script": "手寫體",
//   "Small Caps": "小型大寫字體",
//   "Reset": "重置",
//   "restore all settings to the default values": "恢復全部設定至預設值",
//   "Done": "完成",
//   "Caption Settings Dialog": "字幕設定對話框",
//   "Beginning of dialog window. Escape will cancel and close the window.": "開始對話視窗。離開會取消並關閉視窗",
//   "End of dialog window.": "結束對話視窗",
//   "Seek to live, currently behind live": "快轉至直播，目前為稍早畫面",
//   "Seek to live, currently playing live": "快轉至直播，目前為現場畫面",
//   "progress bar timing: currentTime={1} duration={2}": "{1}/{2}",
//   "{1} is loading.": "{1} 正在載入。",
//   "Exit Picture-in-Picture": "離開子母畫面",
//   "Picture-in-Picture": "子母畫面",
//   "No content": "沒有內容",
//   "Color": "顏色",
//   "Opacity": "不透明度",
//   "Text Background": "文字背景",
//   "Caption Area Background": "字幕區域背景",
//   "Skip forward {1} seconds": "快轉 {1} 秒",
//   "Skip backward {1} seconds": "倒轉 {1} 秒"
// }
// )

// videojs(document.querySelector('.my-video'),{
//   controls: true,
//   autoplay: false,
//   preload: 'auto',
//   disablePictureInPicture: true,
//   enableDocumentPictureInPicture: false,
//   language: 'zh-TW',
//   // playbackRates: [0.5, 1, 1.5, 2],
//   controlBar: {
//     // fullscreenToggle: false,
//     pictureInPictureToggle: false,
//     // skipButtons: {
//     //   forward: 10,
//     //   backward: 10
//     // }
//   },
//   userActions: {
//     hotkeys: function(event) {
//       if (event.which === 32) {
//         if(!this.paused()){
//           this.pause();
//         }else{
//           this.play();
//         }
//       }
//     }
//   }
// },function(){
//   // CurrentTimeDisplay()
//   // this.on('loadedmetadata',function(){
//   //   this.currentTime(10)
//   // })
//   this.on('play',function(){

//   })
//   this.on('pause',function(){
    
//   })
//   console.log(this.currentTime())
//   console.log(this.volume())
// })
// YT
var tag = document.createElement('script');
tag.src = "https://www.youtube.com/iframe_api";
var firstScriptTag = document.getElementsByTagName('script')[0];
firstScriptTag.parentNode.insertBefore(tag, firstScriptTag); 
window.onYouTubePlayerAPIReady = function(){
  let myId = $('#player').data('yt')
  let courseId = $('#player').data('id')
  let watch  // 統計次數
  let save  // 定時儲存時間
  var player = new YT.Player("player", {
    videoId: myId,
    playerVars: {
      playsinline: 1,
      controls: 1,
      wmode: "opaque",
      showinfo: 0,
      rel: 0,
      modestbranding:1,
    },
    events: {
      'onReady': onPlayerReady,
      'onStateChange': onPlayerStateChange
    }
  });
  function onPlayerStateChange(event) {
    clearTimeout(watch)
    clearInterval(save)
    if(event.data == 0 || event.data == 2){
    }
    // 播放
    if(event.data == 1){
      watch = setTimeout(()=>{
        $.post(userAPI('Watch'),{course_id:courseId},function(result){
          let data = JSON.parse(result)
          if(data.status){
            console.log("Counted times")
          }
        })
      },10000)

      save = setInterval(()=>{
        let now = Math.floor(player.getCurrentTime())
        $.post(userAPI('LastWatch'),{course_id:courseId,last_time:now},function(result){
          let data = JSON.parse(result)
          if(data.status){
            console.log("Saved time")
          }else{
            call_alert("danger",data.msg)
            // setTimeout(()=>{ 
            //   location.href=config.login_page
            // },2000)
          }
        })
        // .fail(function(response) {
        //   $('body').prepend(response.responseText);
        // })
      },10000)
    }
  }

  function onPlayerReady(event){
    $.post(userAPI('GetLastTime'),{course_id:courseId},function(result){
      let data = JSON.parse(result)
      // console.log(data)
      if(data.status){
        if(data.user_course.last_time>0){
          player.seekTo(data.user_course.last_time,true); 
          // player.seekTo(20,true); 
          setTimeout(()=>{
            player.pauseVideo()
          },200)
        }
      }else{
        call_alert('danger',data.msg)
        // setTimeout(()=>{
        //   location.href=config.login_page
        // },2000)
      }
    })
    // setInterval(()=>{
    //   let now = player.getCurrentTime();
    //   console.log(now)
    // },1000)
  }
  
  function stopVideo() {
    player.stopVideo();
  }
  function startVideo() {
    player.playVideo();
  }
  
  
  // cuurntTime
  // player.getCurrentTime();
  // setTime
  // player.seekTo(55,true); 
}  