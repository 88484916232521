import {config} from "../function/config"
import {userAPI} from "../function/api"
import call_alert from "../function/alert"

$.get(userAPI('GetUserInfo'),function(result){
  let data = JSON.parse(result)
  // console.log(data)
  if(data.status){
    if(data.user.admin==0){
      function verify(status,course,cotent){
        if(status=="1"){
          $(`#${course}`).removeClass("unfinished")
          $(`#${course}`).find(".target").attr("class","btn btn-dark btn-main").text("下載證書").attr("download",cotent)
        }else{
          $(`#${course}`).find(".target").text("複習 & 交作業").attr("href",config.course[course])
        }
      }
      verify(data.learn.digital,"digital","【國泰人壽】天職學課程認證-數位科技")
      verify(data.learn.financial,"financial","【國泰人壽】天職學課程認證-財務投資")
      verify(data.learn.insurance,"insurance","【國泰人壽】天職學課程認證-保險業務")
      verify(data.learn.math,"math","【國泰人壽】天職學課程認證-數理精算")
    }else{
      call_alert("danger","請確認身份")
      setTimeout(()=>{
        location.href=config.home
      },config.redirect_timeout)
    }
  }else{
    call_alert("danger",data.msg)
    setTimeout(()=>{
      location.href=config.login_page
    },config.redirect_timeout)
  }
})


let certificate_html = `
    <h4>{{name}} 同學</h4>
    <p class="date">自 {{year}} 年 {{month}} 月 {{day}} 日</p>
    <p>參與本公司  {{class}}  職系系列課程</p>
    <p>取得課程合格認證 特此說明</p>
    `
let certificate_loading = `
  <div class="modal fade" data-bs-backdrop="static" id="certificate-create" tabindex="-1" data-bs-keyboard="false">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content certificate-loading">
        <p>下載 {{title}} 完課證書</p>
        <div class="loading">
          <div class="spinner-border text-success" role="status"></div>證書生產中...
        </div>
      </div>
    </div>
  </div>
  `

$('.learn-certificate .certificate a.btn').on('click',function(e){
  if(typeof($(this).attr("download"))!="undefined"){
    e.preventDefault()
    let filename = $(this).attr('download')
    let title = $(this).siblings('.title').find('h3').text().trim()
    let image = $(this).siblings('figure').find('img').attr('src')
    let push = certificate_html.replace(/{{class}}/g,title)
    // modal
    $('body').append(certificate_loading.replace(/{{title}}/g,title))
    let certificate_create = new bootstrap.Modal('#certificate-create')
    certificate_create.show()
  
    function result_error(){
      setTimeout(()=>{
        certificate_create.hide()
        setTimeout(()=>{
          call_alert('danger','證書產生失敗，請聯絡客服人員。')
          $('#certificate-create').remove()
        },config.modal_timeout)
      },1500)
    }
    
    $.get(userAPI('GetUserInfo'),function(result){
      let data = JSON.parse(result)
      if(result){
        if(data.status){
          let create_time = data.user.credate.split(' ')[0].split('-')
          push = push.replace(/{{year}}/g,create_time[0])
                     .replace(/{{month}}/g,create_time[1])
                     .replace(/{{day}}/g,create_time[2])
                     .replace(/{{name}}/g,data.user.name)
          // 傳輸資訊
          $('#certificate .push').html(push)
          $('#certificate figure').html(`<img src="${image}" alt="${title}">`)
          html2canvas(document.getElementById('certificate'),{
            onclone: function(el){
              el.getElementById('certificate').style.display="block"
            },
          }).then(function(canvas) {
            // document.body.appendChild(canvas);
            // canvas.style.display="none"
            var doc = new jspdf({
              unit: 'px',
              orientation: 'l',
              format: [canvas.width / 3, canvas.height / 3]
            })
            var image = canvas.toDataURL('image/jpeg', 1.0);
            doc.addImage(image, 0, 0, canvas.width / 3, canvas.height / 3);
            setTimeout(()=>{
              certificate_create.hide()
              doc.save(`${filename}.pdf`)
              setTimeout(()=>{
                $('#certificate-create').remove()
              },1000)
            },1500)
          });
        }else{
          result_error()
        }
      }else{
        result_error()
      }
    })
  }
})