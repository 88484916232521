export default function call_alert(status,text){
  let temp = `<div class="alert alert-${status}" role="alert">${text}</div>`
  // status
  // $('#alert-wrap .alert').attr('class',`alert alert-${status}`).text(text)
  $('#alert-wrap').html(temp)
  // action
  $('#alert-wrap').addClass('show')
  setTimeout(()=>{
    $('#alert-wrap').removeClass('show')
  },2500)
}