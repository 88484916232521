import {config} from "../function/config"
import is from "../function/is"
import call_alert from "../function/alert"
import {userAPI,adminAPI} from "../function/api"
import {GetCity,GetSchool,GetDepartment} from "../function/school"

jQuery.validator.addMethod("password", function(value, element) {
  // allow any non-whitespace characters as the host part
  return this.optional( element ) || /^(?=.*\d)(?=.*[a-zA-Z])(?!.*\s).{8,99}$/.test( value );
}, '密碼格式有誤，請包含數字及英文，長度大於7');

// jQuery.validator.addMethod("old-password", function(value, element) {
//   // allow any non-whitespace characters as the host part
//   return this.optional( element ) || /^(?=.*\d)(?=.*[a-zA-Z])(?!.*\s).{8,99}$/.test( value );
// }, '密碼格式有誤，請包含數字及英文，長度大於7');

jQuery.validator.addMethod("email", function(value, element) {
  // allow any non-whitespace characters as the host part
  return this.optional( element ) || /^[\w\.=-]+@[\w\.-]+\.[\w]{2,3}$/.test( value );
  // return this.optional( element ) || /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/.test( value );
}, '信箱格式有誤');

// 登入
if(is('form#login')){
  let login = $("form#login").validate({
    errorClass: "is-invalid",
    // validClass: "is-valid",
    onfocusout: function(element) {
      $(element).valid()
    },
    // debug: true,
    rules: {
      email: {
        required: true
      },
      password: {
        required: true
      },
    },
    submitHandler: function(form) {
      let data = $(form).serializeArray()
      $.post(userAPI("Login"),data,function(result){
        let data = JSON.parse(result)
        // console.log(data)
        if(data.status){
          call_alert('success',data.msg)
          setTimeout(()=>{
            location.href=config.login_redirect
          },1000)
        }else{
          // call_alert('danger',data.msg)
          if(data.msg.indexOf('帳號')!=-1){
            login.showErrors({
              "email":data.msg,
            })
          }else{
            login.showErrors({
              "password":"密碼錯誤",
            })
          }
        }
      })
    }
  });
}
// 忘記密碼
if(is('form#forgot')){
  let forgot = $("form#forgot").validate({
    errorClass: "is-invalid",
    // validClass: "is-valid",
    onfocusout: function(element) {
      $(element).valid()
    },
    // debug: true,
    rules: {
      email: {
        required: true
      },
    },
    submitHandler: function(form) {
      let data = $(form).serializeArray()
      $.post(userAPI("Forgot"),data,function(result){
        let data = JSON.parse(result)
        if(data.status){
          call_alert('success',data.msg)
        }else{
          // call_alert('danger',data.msg)
          forgot.showErrors({
            "email":data.msg
          })
        }
      })
    }
  });
}
// 忘記修改密碼
if(is('form#change-password')){
  let change_password = $("form#change-password").validate({
    errorClass: "is-invalid",
    onfocusout: function(element) {
      $(element).valid()
    },
    // validClass: "is-valid",
    // debug: true,
    rules: {
      password: {
        required: true,
      },
      passwordConfirm: {
        equalTo: "#password",
        required: true,
      },
      token: {
        required: true
      }
    },
    messages: {
      passwordConfirm: {
        equalTo: "密碼不符"
      }
    },
    submitHandler: function(form) {
      let data = $(form).serializeArray()
      $.post(userAPI("ChangePwd"),data,function(result){
        let data = JSON.parse(result)
        if(data.status){
          call_alert('success',data.msg)
          setTimeout(()=>{
            location.href=config.edit_pwd_redirect
          },config.redirect_timeout)
        }else{
          call_alert('danger',data.msg)
        }
      })
    }
  });
}
// 登入修改密碼
if(is('form#edit-pwd-form')){
  let edit_pwd = new bootstrap.Modal('#edit-pwd')
  let change_password = $("form#edit-pwd-form").validate({
    errorClass: "is-invalid",
    onfocusout: function(element) {
      $(element).valid()
    },
    // validClass: "is-valid",
    // debug: true,
    rules: {
      "old_password": {
        required: true,
      },
      "new_password": {
        required: true,
      },
      passwordConfirm: {
        equalTo: "#password",
        required: true,
      },
    },
    messages: {
      passwordConfirm: {
        equalTo: "密碼不符"
      }
    },
    submitHandler: function(form) {
      let data = $(form).serializeArray()
      $.post(userAPI("EditPwd"),data,function(result){
        let data = JSON.parse(result)
        if(data.status){
          edit_pwd.hide()
          setTimeout(()=>{
            call_alert('success',data.msg)
            $("#edit-pwd-form")[0].reset()
            $('#old-password').trigger("input")
          },config.modal_timeout)
        }else{
          call_alert('danger',data.msg)
        }
      })
    }
  });
  let timer
  $('#old-password').on('input',function(){
    let val = $(this).val()
    let able = ['#password','#passwordConfirm']
    let check = val.match(/^(?=.*\d)(?=.*[a-zA-Z])(?!.*\s).{8,99}$/)
    if(check){
      clearTimeout(timer)
      timer=setTimeout(()=>{
        let check = $.post(userAPI("Login"),{
          email: $('#email').text().trim(),
          password: $('#old-password').val(),
        },function(result){
          let data = JSON.parse(result)
          if(data.status){
            able.forEach((e)=>{
              $(e).removeAttr('disabled')
            })
          }else{
            change_password.showErrors({
              "old_password": "密碼錯誤"
            })
            able.forEach((e)=>{
              $(e).attr('disabled',true)
            })
          }
        })
      },200)
    }else{
      able.forEach((e)=>{
        $(e).attr('disabled',true)
      })
    }
  })
  
}
// 管理員修改密碼
if(is('form#edit-pwd-admin-form')){
  let edit_pwd = new bootstrap.Modal('#edit-pwd-admin')
  let change_password = $("form#edit-pwd-admin-form").validate({
    errorClass: "is-invalid",
    onfocusout: function(element) {
      $(element).valid()
    },
    // validClass: "is-valid",
    // debug: true,
    rules: {
      "new_password": {
        required: true,
      },
      passwordConfirm: {
        equalTo: "#password",
        required: true,
      },
    },
    messages: {
      passwordConfirm: {
        equalTo: "密碼不符"
      }
    },
    submitHandler: function(form) {
      let data = $(form).serializeArray()
      $.post(adminAPI("EditPwd"),data,function(result){
        let data = JSON.parse(result)
        if(data.status){
          edit_pwd.hide()
          setTimeout(()=>{
            call_alert('success',data.msg)
            $("#edit-pwd-admin-form")[0].reset()
          },config.modal_timeout)
        }else{
          call_alert('danger',data.msg)
        }
      })
    }
  });
}
// 註冊
if(is('form#register') || is('form#admin-register')){
  function select_init(item){
    $(item).html('<option value="">請選擇</option>')
  }
  function form_disabled(item,status){
    $(item).attr('disabled',status)
  }
  
  GetCity()
  GetSchool()
  GetDepartment()

  // form
  let register = $("form#register,form#admin-register").validate({
    errorClass: "is-invalid",
    // validClass: "is-valid",
    onfocusout: function(element) {
      $(element).valid()
    },
    // debug: true,
    rules: {
      email: {
        required: true,
      },
      password: {
        required: true,
      },
      passwordConfirm: {
        equalTo: "#password",
        required: true,
      },
      name: {
        required: true
      },
      year: {
        required: true,
        maxlength: 4
      },
      month: {
        required: true,
        maxlength: 2
      },
      date: {
        required: true,
        maxlength: 2
      },
      city: {
        required: true
      },
      school: {
        required: true
      },
      dept: {
        required: true
      },
      other_school: {
        required: true
      },
      other_department: {
        required: true
      },
      education: {
        required: true
      },
      phone_no: {
        required: true
      },
      policy: {
        required: true
      },
    },
    messages: {
      city: "請選擇學校所在縣市",
      school: "請選擇學校",
      dept: "請選擇系別",
      education: "請選擇學歷",
      policy: "",
      passwordConfirm: {
        equalTo: "密碼不符"
      }
    },
    submitHandler: function(form) {
      let data = $(form).serializeArray()
      // console.log(data)
      if(is("form#register")){
        $.post(userAPI("Register"),data,function(result){
          let data = JSON.parse(result)
          if(data.status){
            call_alert("success",data.msg)
            setTimeout(()=>{
              location.href=config.register_redirect
            },config.redirect_timeout)
          }else{
            call_alert("danger",data.msg)
          }
        })
      }
      if(is("form#admin-register")){
        $.post(adminAPI("Register"),data,function(result){
          let data = JSON.parse(result)
          if(data.status){
            call_alert("success",data.msg)
            let admin_setting = {
              phone: "",
              school_id: "",
              type: $(`[name="type"]`).val(),
              page: "1",
              page_size: "10",
            }
            let string_admin_setting = JSON.stringify(admin_setting)
            sessionStorage.setItem("admin",string_admin_setting)
            setTimeout(()=>{
              location.href=config.admin_register_redirect
            },config.redirect_timeout)
          }else{
            call_alert("danger",data.msg)
          }
        })
        // .fail(function(xhr, status, error) {
        //   $("body").prepend(xhr.responseText)
        // })
      }
    }
  });
  $('#account').on('blur',function(){
    let val = $(this).val()
    if(val){
      $.post(userAPI("Register"),{email:val},function(result){
        let data = JSON.parse(result)
        if(!data.status){
          if(data.msg=="該信箱已被申請"){
            register.showErrors({
              "email": "該信箱已被申請"
            });
            // call_alert('danger','該信箱已被申請')
          }
        }
      })
    }
  })
}