import {userAPI} from "../function/api"
import {config} from "../function/config"
import call_alert from "../function/alert"
setTimeout(()=>{
  $.get(userAPI('GetUserInfo'),function(result){
    let data = JSON.parse(result)
    // console.log(data)
    if(data.status=="1"){
      $('#name').text(data.user.name).removeAttr('class')
      $('#email-name').text('Email').removeAttr('class')
      $('#email').text(data.user.email).removeAttr('class')
      $('#edit-pwd-text').text('重設密碼').removeAttr('class')
      if(data.user.admin==0){
        $('#phone-name').text('聯絡電話').removeAttr('class')
        $('#phone').text(data.user.phone_no).removeAttr('class')
        if(data.user.school_id!="-1"){
          $.post(userAPI('GetSchool'),{city_id:data.user.city},function(result){
            let schools = JSON.parse(result)
            let school
            if(schools.status){
              school = schools.school.find(e=>e.school_id==data.user.school_id)
              $.post(userAPI('GetDepartment'),{school_id:data.user.school_id},function(result){
                let depts = JSON.parse(result)
                let dept
                if(depts.status){
                  dept = depts.department.find(e=>e.dept_id==data.user.department_id)
                }
                $('#school').append(" "+dept.dept_name).removeAttr('class')
              })
            }
            $('#school').text(school.school_name).removeAttr('class')
          })
        }else{
          $('#school').text(data.user.other_school+" "+data.user.other_department).removeAttr('class')
        }
      }else{
        $('#phone-name,#phone').remove()
        $('#school').text("管理員").removeAttr('class')
      }
    }else{
      call_alert("danger",data.msg)
      setTimeout(()=>{
        location.href=config.home
      },config.redirect_timeout)
    }
  })
},500)
