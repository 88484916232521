import {config} from "./config"

function userAPI(url){
  // return "https://mission-in-cathaylife.url.tw/cathaylife/UserApi/"+url
  return config.url+"UserApi/"+url
}

function adminAPI(url){
  // return "https://mission-in-cathaylife.url.tw/cathaylife/AdminApi/"+url
  return config.url+"AdminApi/"+url
}


export {userAPI,adminAPI} 