import {userAPI } from "./api"


function select_init(item){
  $(item).html('<option value="">請選擇</option>')
}
function form_disabled(item,status){
  $(item).attr('disabled',status)
}
// 縣市
function GetCity(value){
  $.get(userAPI("GetCity"),function(result){
    let data = JSON.parse(result)
    if(data.status){
      for(let i in data.city){
        $('#city').append(`<option value="${data.city[i].country_id}" ${(data.city[i].country_id == value)?"selected":""}>${data.city[i].country_name}</option>`)
      }
      if(value!=""){
        $("#city").trigger("change")
      }
    }
  })
}
// 學校
function GetSchool(value){
  $('#city').on('change',function(){
    let val = $(this).val()
    if(val){
      $.post(userAPI("GetSchool"),{city_id:val},function(result){
        let data = JSON.parse(result)
        if(data.status){
          form_disabled('#school',false)
          select_init('#school,#dept')
          for(let i in data.school){
            $('#school').append(`<option value="${data.school[i].school_id}" ${(data.school[i].school_id == value)?"selected":""}>${data.school[i].school_name}</option>`)
          }
          $('#school').append(`<option value="-1" ${(value == "-1")?"selected":""}>其他</option>`)
          if(value!=""){
            $("#school").trigger("change")
          }
        }else{
          form_disabled('#school,#dept',true)
          select_init('#school,#dept')
        }
      })
    }else{
      form_disabled('#school,#dept',true)
      select_init('#school,#dept')
    }
  })
}
// 科系
function GetDepartment(value){
  $('#school').on('change',function(){
    let val = $(this).val()
    switch(val){
      case "":
        $('#dept').attr('disabled',true).html('<option value="">請選擇</option>')
        $(this).closest('form').find('.other').hide().find('input').attr('disabled',true)
        break
      case "-1":
          $('#dept').attr('disabled',false).html('<option value="-1" selected>其他</option>')
          $(this).closest('form').find('.other').show().find('input').attr('disabled',false)
        break
      default:
        $(this).closest('form').find('.other').hide().find('input').attr('disabled',true)
        $.post(userAPI("GetDepartment"),{school_id:val},function(result){
          let data = JSON.parse(result)
          if(data.status){
            form_disabled('#dept',false)
            select_init('#dept')
            for(let i in data.department){
              $('#dept').append(`<option value="${data.department[i].dept_id}" ${(data.department[i].dept_id == value)?"selected":""}>${data.department[i].dept_name}</option>`)
            }
          }else{
            form_disabled('#dept',true)
            select_init('#dept')
          }
        })
        break
    }
  })
}

export {GetCity,GetSchool,GetDepartment}