// 測試
// const config = {
//   url: "http://dev.orientw.com/",
//   home: "http://dev.orientw.com/",
//   redirect_timeout: 800,
//   modal_timeout: 800,
//   login_redirect: "http://dev.orientw.com/",
//   register_redirect: "http://dev.orientw.com/",
//   edit_pwd_redirect: "http://dev.orientw.com/",
//   admin_register_redirect: "http://dev.orientw.com/memberlist",
//   login_page: "http://dev.orientw.com/login",
//   admin_list_page: "http://dev.orientw.com/memberlist",
//   profile_page: "http://dev.orientw.com/profile",
//   learning_page: "http://dev.orientw.com/learning",
//   user: {
//     type: "",      // user || admin
//     logined: ""    // true || false
//   },
//   course: {
//     digital: "http://dev.orientw.com/course/digital",
//     financial: "http://dev.orientw.com/course/financial",
//     insurance: "http://dev.orientw.com/course/insurance",
//     math: "http://dev.orientw.com/course/math"
//   }
// }
// 正式
const config = {
  url: "https://mission-in-cathaylife.url.tw/",
  home: "https://mission-in-cathaylife.url.tw/",
  redirect_timeout: 800,
  modal_timeout: 800,
  login_redirect: "https://mission-in-cathaylife.url.tw/",
  register_redirect: "https://mission-in-cathaylife.url.tw/",
  edit_pwd_redirect: "https://mission-in-cathaylife.url.tw/",
  admin_register_redirect: "https://mission-in-cathaylife.url.tw/memberlist",
  login_page: "https://mission-in-cathaylife.url.tw/login",
  admin_list_page: "https://mission-in-cathaylife.url.tw/memberlist",
  profile_page: "https://mission-in-cathaylife.url.tw/profile",
  learning_page: "https://mission-in-cathaylife.url.tw/learning",
  user: {
    type: "",      // user || admin
    logined: ""    // true || false
  },
  course: {
    digital: "https://mission-in-cathaylife.url.tw/course/digital",
    financial: "https://mission-in-cathaylife.url.tw/course/financial",
    insurance: "https://mission-in-cathaylife.url.tw/course/insurance",
    math: "https://mission-in-cathaylife.url.tw/course/math"
  }
}

export {config};