// import {config} from './function/config';
import is from './function/is';
import enable_svg from './function/svg';
// import LoadPage from "./class/loading";
import KOS from "./class/kos";
// import call_alert from "./function/alert";
import {userAPI} from "./function/api"

if(typeof(Cookies.get("visit"))=="undefined"){
  Cookies.set("visit",true,{ expires: 0.5 })
  $.post(userAPI("Visit"))
}

// Class Loading Width KOS
$(window).on('load',function(){
  // loading & kos
  let loadArr = {
    container:'#app-loading',
    wrap: '#page',
    duration: 400,
    delay: 600,
    target: [
      //'a:not([href^="#"]):not([target^="_blank"])',
    ],
    kos: [
      { item: "h1", kos: "fadeUp", position: "1", loop: false },
    ]
  }
  // var loading = new LoadPage(loadArr)
  var kos = new KOS();

  // page banner
  if(is('.page-banner')){
    $('.page-banner .banner-content').addClass('active')
  }
  
})

$(function(){
  require("./module/header");

  if(is("#footer")){
    $.get(userAPI("GetVisit"),function(result){
      let res = JSON.parse(result)
      if(res.status==1){
        $(".viewers").attr("data-number",res.visit_count)
      }else{
        $(".viewers").attr("data-number",0)
      }
      let numbers = $('#footer .viewers').data('number').toString().split('')
      for(let i in numbers){
        $('#footer .viewers').append(`<span>${numbers[i]}</span>`)
      }
    })
  }
  
  // index line
  if(is('#intro-line')){
    new Vivus('intro-line', { duration: 100, file: '/assets/images/img-home-origin-icon-3.svg' });
  }
  // index swiper
  if(is(".home-banner .swiper")){
    var home_banner = new Swiper(".banner-swiper", {
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
        renderBullet: function (index, className) {
          return '<span class="' + className + '">' + (index + 1) + "</span>";
        },
      },
    });
  }
  // index swiper
  if(is(".home-carousel .swiper")){
    var home_banner = new Swiper(".swiper.carousel", {
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
        // renderBullet: function (index, className) {
        //   return '<span class="' + className + '">' + (index + 1) + "</span>";
        // },
      },
    });
  }
  // faq list
  $('.faq-list .head').on('click',function(){
    $(this).closest('li').toggleClass('active').find('.body').stop().slideToggle()
    $(this).closest('li').siblings('li').removeClass('active').find('.body').stop().slideUp()
  })
  // disable link
  $(".btn[disabled]").on("click",function(e){
    e.preventDefault()
  })

  // video page
  // if(is('.my-video')){
  if(is('.video-wrap')){
    require("./module/video")
  }

  // member ajax
  require("./module/ajax")

  if(is(".page-course")){
    require("./module/course")
  }
  
  // member certificate
  if(is('.page-learn')){
    require("./module/certificate")
  }
  
  // member profile
  if(is('.page-profile')){
    require("./module/profile")
  }
  
  // member profile
  if(is('#member-table')){
    require("./module/admin")
  }

  // member info
  if(is("#admin-edit")){
    require("./module/user")
  }

  enable_svg()
})